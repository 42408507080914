<template>
    <div>
        <p class="h5 u-text-primary">Software Entwickler mit Herz.</p>
        <h1 class="m-0">Alle haben Bock.<br>Aber <span>Nick</span> hat <span>Böcker</span></h1>
    </div>
</template>

<script>
export default {
    name: 'Slogan',
}
</script>
